import Vue from 'vue'
import { EagleTreeConfigInterface } from '@/components/tree/types/tree'
class treeConfig {
  protected vm?: Vue
  protected treeKey?: string

  get(vm: Vue, treeKey: string): EagleTreeConfigInterface {
    this.vm = vm
    this.treeKey = treeKey

    return {
      pageTitle: this.vm?.$t('page.product_category'),
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_PRODUCT']),
      label: node => node.name,
      // @ts-ignore
      max: this.vm.productCategoryMaxDepth,
    }
  }
}

export default new treeConfig()